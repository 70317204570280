import React from 'react';
import Navbar from './components/Navbar/Navbar.jsx';
import ValuationForm from './components/Form/ValuationForm.jsx';


const App = () => {
  return (
    <div>
      <Navbar />
      <ValuationForm />
    </div>
  );
};

export default App;

