import React, { useState } from 'react';
import './Navbar.css';

const Navbar = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
 
  const toggleNav = () => {
    setIsNavExpanded(!isNavExpanded);
  };

  // Call this function when a nav link is clicked
  const closeNav = () => {
    setIsNavExpanded(false);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">MAIDA.CO</div>
      <button
        className={`navbar-toggle ${isNavExpanded ? "expanded" : ""}`}
        onClick={toggleNav}
        aria-label="Toggle navigation"
      >
        <div className="hamburger-icon">
          <span className="bar"></span>
        </div>
        <div className="hamburger-icon">
          <span className="bar"></span>
        </div>
        <div className="hamburger-icon">
          <span className="bar"></span>
        </div>
      </button>
      <div className={`navbar-links ${isNavExpanded ? "expanded" : ""}`}>
        <a href="https://maida.co/" className="nav-link" onClick={closeNav} >
          HOME
        </a>
        <a href="https://maida.co/services/" className="nav-link" onClick={closeNav}>
          SERVICES
        </a>
        <a href="https://maida.co/about-us/" className="nav-link" onClick={closeNav}>
          ABOUT US
        </a>
        <a href="https://maida.co/contact/" className="nav-link" onClick={closeNav}>
          CONTACT US
        </a>
        <a href="/" className="nav-link nav-link-special" onClick={closeNav}>
          ONLINE APP VALUATION
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
