import React, { useState } from "react";
import "./UnPublishedForm.css";
import ProgressBar from "../../ProgressBar/ProgressBar";
import axios from 'axios'; // Import only if you're using Axios


const UnpublishedForm = () => {
  const [appLink, setAppLink] = useState("");
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [appLinkError, setAppLinkError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [surnameError, setSurnameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [linkedinError, setLinkedinError] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 2;

  const validateURL = (url) => {
    const pattern = new RegExp(
      "^(https?:\\/\\/(www\\.)?)|(http:\\/\\/(www\\.)?)|(www\\.)" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(url);
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleNext = () => {
    let hasError = false;
    if (currentStep === 1) {
      if (!appLink) {
        setAppLinkError("App link is required");
        return;
      }
      if (!validateURL(appLink)) {
        setAppLinkError("Please enter a valid URL");
        return;
      }
      if (!hasError) {
        setLoading(true);
    
        // Simulate a loading delay
        setTimeout(() => {
          setLoading(false);
          setCurrentStep(currentStep + 1);
        }, 2000); // Adjust delay as needed
      }
    }
  };

  const handleContactDetails  = async () => {
    setLoading(true);
    let isValid = true;
    if (!firstName) {
      setFirstNameError("First name is required");
      isValid = false;
    } else {
      setFirstNameError("");
    }
    if (!surname) {
      setSurnameError("Surname is required");
      isValid = false;
    } else {
      setSurnameError("");
    }
    if (!email || !validateEmail(email)) {
      setEmailError("A valid email is required");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!linkedin) {
      setLinkedinError("Linkedin link is required");
      return;
    }
    if (!validateURL(linkedin)) {
      setLinkedinError("Please enter a valid URL");
      return;
    }
    setLinkedinError("");
    if (isValid) {
        try {
    const formData = {
      appLink: appLink,
      firstName: firstName,
      surname: surname,
      email: email,
      linkedin: linkedin
    }
          // Using Axios (if installed)
          const response = await axios.post('https://apps.maida.co:3000/send-email', formData);
    
          if (response.status === 200) {
            console.log('Email sent successfully');
            window.location.href = 'https://maida.co/thankyou';
          } else {
            console.error('Failed to send email');
          }
        } catch (error) {
          console.error('Error sending email:', error);
        }
        finally {
          // Use setTimeout to maintain loading state for a specified duration
          setTimeout(() => {
            setLoading(false); // Stop loading after the delay
            window.location.href = "https://maida.co/thankyou"; // Redirect after loading
          }, 4000); // Set this to your desired delay time (e.g., 2000 milliseconds for 2 seconds)
        }
      }
    };

  

  return (
    <div className="unpublished-form">
      <h1 className="unpublished-title">MAIDA.CO</h1>
      <button className="valuation-calculator-btn">
        Free App Valuation Calculator
      </button>
      <ProgressBar step={currentStep} totalSteps={totalSteps} />
      {currentStep === 1 && (
        <>
          <h2 className="unpublished-heading">
            Tell Us How Much You Can Earn For Your App?
          </h2>
          <p className="unpublished-description">
            Can you provide a link to your application's listing on the relevant
            app store?
          </p>
          <input
            type="text"
            placeholder="Paste Link"
            name="appLink"
            value={appLink}
            onChange={(e) => {
              setAppLink(e.target.value);
              if (appLinkError) {
                setAppLinkError(""); // Clear the appLinkError if it exists
              }
            }}
            className={`app-link-input ${appLinkError ? "input-error" : ""}`}
          />
          {appLinkError && <p className="error-message">{appLinkError}</p>}
          <button onClick={handleNext} className="next-btn">
            {loading ? <div className="loader"></div> : "NEXT"}
          </button>
        </>
      )}
      {currentStep === 2 && (
        <>
          <h2 className="unpublished-heading">
            We've Calculated Your Valuation..
          </h2>
          <p className="unpublished-description">
            To access your valuation please fill in your contact details
          </p>
          <input
            type="text"
            placeholder="First Name..."
            name="firstName"
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
              if (firstNameError) {
                setFirstNameError(""); // Clear the appLinkError if it exists
              }
            }}
            className="contact-input"
          />
          {firstNameError && <p className="error-message">{firstNameError}</p>}
          <input
            type="text"
            placeholder="Sur Name..."
            name="surname"
            value={surname}
            onChange={(e) => {
              setSurname(e.target.value);
              if (surnameError) {
                setSurnameError(""); // Clear the appLinkError if it exists
              }
            }}
            className="contact-input"
            required
          />
          {surnameError && <p className="error-message">{surnameError}</p>}
          <input
            type="email"
            placeholder="Email Address..."
            name="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              if (emailError) {
                setEmailError(""); // Clear the appLinkError if it exists
              }
            }}
            className="email-input"
            required
          />
          {emailError && <p className="error-message">{emailError}</p>}

          <input
            type="text"
            placeholder="LinkedIn..."
            name="linkedin"
            value={linkedin}
            onChange={(e) => {
              setLinkedin(e.target.value);
              if (linkedinError) {
                setLinkedinError(""); // Clear the appLinkError if it exists
              }
            }}
            className="contact-input"
            required
          />
          {linkedinError && <p className="error-message">{linkedinError}</p>}

          <button onClick={handleContactDetails} className="unpublished-calculate">
             {loading ? <div className="loader"></div> : "CALCULATE NOW"}
          </button>
        </>
      )}
    </div>
  );
};

export default UnpublishedForm;
