import React, { useState } from "react";
import "./PublishedForm.css"; // Make sure to create this CSS file
import ProgressBar from "../../ProgressBar/ProgressBar"; // Adjust the import path as necessary
import axios from "axios"; // Import only if you're using Axios

const PublishedForm = () => {
  const [isCheckboxChecked, setIsCheckboxChecked] = React.useState(false);

  const [appLink, setAppLink] = useState("");
  const [downloadednumbers, setDownloadednumbers] = useState("");
  const [error, setError] = useState("");
  const [revenue, setRevenue] = useState("");
  const [Campaign, setCampaign] = useState("");
  const [AppPlateform, setAppPlateform] = useState("");
  const [TrafficType, setTrafficType] = useState("");
  const [RevenueSource, setRevenueSource] = useState("");
  const [AppType, setAppType] = useState("");
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [valuation, setValuation] = useState(0);
  const [loading, setLoading] = useState(false);

  const [appLinkError, setAppLinkError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [surnameError, setSurnameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [linkedinError, setLinkedinError] = useState("");
  const [revenueError, setRevenueError] = useState("");
  const [campaignError, setCampaignError] = useState("");

  const [setMultiplier] = useState(100);
  const [currentStep, setCurrentStep] = useState(1);

  const [appTitle, setAppTitle] = useState("");
  const [appRatings, setAppRatings] = useState("");
  const [appDownloads, setAppDownloads] = useState("");
  const [appReviews, setAppReviews] = useState("");
  const [appStoreName, setAppStoreName] = useState("");
  const [appLogo, setAppLogo] = useState("");

  const totalSteps = 9; // Assuming there are 2 steps in the form

  const validateURL = (url) => {
    const pattern = new RegExp(
      "^(https?:\\/\\/(www\\.)?)|(http:\\/\\/(www\\.)?)|(www\\.)" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(url);
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleNext = async () => {
    // setLoading(true); 
    let hasError = false;

    if (currentStep === 1) {
      if (!appLink) {
        setAppLinkError("App link is required");
        return;
      }
      if (!validateURL(appLink)) {
        setAppLinkError("Please enter a valid URL");
        return;
      }

      try {
        setLoading(true); 
        const response = await axios.get(
          `https://apps.maida.co:3000/scrape?url=${(appLink)}`
        );
        const scrapedData = response.data;
        setAppTitle(scrapedData.title);
        setAppRatings(scrapedData.ratings);
        setAppDownloads(scrapedData.downloads);
        setAppReviews(scrapedData.reviews);
        setAppStoreName(scrapedData.storename);
        setAppLogo(scrapedData.applogo);
      } catch (error) {
        console.error("Error scraping data:", error);
        setAppLinkError("Failed to fetch app details. Please check the URL.");
        setLoading(false); 
        return;
      }
    } else if (currentStep === 2) {
      if (!AppPlateform) {
        setError("Please select an option for your app");
        return;
      }
    } else if (currentStep === 3) {
      if (!AppType) {
        setError("Please select an option for your app");
        return;
      }
    } else if (currentStep === 4) {
      if (!TrafficType) {
        setError("Please select an option for your app");
        return;
      }
    } else if (currentStep === 5) {
      if (downloadednumbers === null || downloadednumbers === "") {
        setError("Please enter a download number");
        return;
      }
    } else if (currentStep === 6) {
      if (revenue === null || revenue === "") {
        setRevenueError("Please enter the revenue amount for the last 90 days");
        hasError = true;
      }
      if (Campaign === null || Campaign === "") {
        setCampaignError(
          "Please enter the campaign spending amount for the last 90 days"
        );
        hasError = true;
      }
      if (hasError) return;
    } else if (currentStep === 7) {
      if (!RevenueSource) {
        setError("Please select an option for your app");
        return;
      }
    }

    if (!hasError) {
      setLoading(true);

      // Simulate a loading delay
      setTimeout(() => {
        setLoading(false);
        setCurrentStep(currentStep + 1);
      }, 1000); // Adjust delay as needed
    }
  };

  const handleContactDetails = () => {
    let hasError = false;
    if (!firstName) {
      setFirstNameError("First name is required");
    } else {
      setFirstNameError("");
    }
    if (!surname) {
      setSurnameError("Surname is required");
    } else {
      setSurnameError("");
    }
    if (!email || !validateEmail(email)) {
      setEmailError("A valid email is required");
    } else {
      setEmailError("");
    }

    if (!linkedin) {
      setLinkedinError("Linkedin link is required");
      return;
    }
    if (!validateURL(linkedin)) {
      setLinkedinError("Please enter a valid URL");
      return;
    }

    if (!hasError) {
      setLoading(true); // Start loading

      // Simulate a loading delay (or perform actual async operations)
      setTimeout(() => {
        setLinkedinError("");
        setError("");
        calculateValuationWithSlider();
        setCurrentStep(currentStep + 1); // Proceed to the next step
        setLoading(false); // Stop loading
      }, 4000); // Adjust delay as needed
    }
  };

  const handleChange = (event) => {
    setAppPlateform(event.target.value);
    if (error) {
      setError(""); // Clear the error when a radio button is selected
    }
  };

  const handleChange1 = (event) => {
    setTrafficType(event.target.value);
    if (error) {
      setError(""); // Clear the error when a radio button is selected
    }
  };
  const handleChange2 = (event) => {
    setRevenueSource(event.target.value);
    if (error) {
      setError(""); // Clear the error when a radio button is selected
    }
  };

  const handleChange3 = (event) => {
    setAppType(event.target.value);
    if (error) {
      setError(""); // Clear the error when a radio button is selected
    }
  };

  const handleSliderChange = (event) => {
    setMultiplier(event.target.value);
  };

  // Updated calculation to use the multiplier
  const calculateValuationWithSlider = () => {
    const monthlyRevenue = Math.floor(Number(revenue) / 3); // Assuming revenue is total for the last 90 days
    const monthlyCampaignSpending = Math.floor(Number(Campaign) / 3); // Same assumption as revenue
    const determineUserBaseValue = (downloadedNumbers) => {
      if (downloadedNumbers >= 0 && downloadedNumbers < 100000) {
        return 100;
      } else if (downloadedNumbers >= 100000 && downloadedNumbers < 500000) {
        return 200;
      } else if (downloadedNumbers >= 500000 && downloadedNumbers < 1000000) {
        return 400;
      } else if (downloadedNumbers >= 1000000 && downloadedNumbers < 5000000) {
        return 500;
      } else if (downloadedNumbers > 5000000) {
        return 700;
      }
      return 0; // Default return value if none of the conditions are met
    };

    const userBaseValue = determineUserBaseValue(downloadednumbers);

    const revenueMinusSpending = monthlyRevenue - monthlyCampaignSpending;
    const adjustedRevenueMinusSpending =
      revenueMinusSpending > 0 ? revenueMinusSpending : 0;

    const valuationMultiplier = 20;
    const appValuation =
      adjustedRevenueMinusSpending * valuationMultiplier + userBaseValue;

    setValuation(appValuation);
  };

  const handlePage = async () => {
    setLoading(true);
    try {
      // Replace with the actual data you want to send
      const formData = {
        appLink: appLink,
        firstName: firstName,
        surname: surname,
        email: email,
        linkedin: linkedin,
        downloadednumbers: downloadednumbers,
        revenue: revenue,
        Campaign: Campaign,
        AppPlateform: AppPlateform,
        TrafficType: TrafficType,
        RevenueSource: RevenueSource,
        AppType: AppType,
        valuation: valuation,
        appTitle: appTitle,
        appDownloads: appDownloads,
        appReviews: appReviews,
        appStoreName: appStoreName,
        appRatings: appRatings,

      
      };

      // Sending a POST request to your server
      await axios.post("https://apps.maida.co:3000/send-email", formData);

      // If the request is successful, redirect to the thank you page
      window.location.href = "https://maida.co/thankyou";
    } catch (error) {
      console.error("Error sending email:", error);
      // Handle the error as needed (e.g., show an error message)
    } finally {
      // Use setTimeout to maintain loading state for a specified duration
      setTimeout(() => {
        setLoading(false); // Stop loading after the delay
        window.location.href = "https://maida.co/thankyou"; // Redirect after loading
      }, 3000); // Set this to your desired delay time (e.g., 2000 milliseconds for 2 seconds)
    }
  };

  return (
    <div className="published-form">
      <h1 className="published-title">MAIDA.CO</h1>
      <button className="valuation-calculator-btn">
        Free App Valuation Calculator
      </button>
      <ProgressBar step={currentStep} totalSteps={totalSteps} />
      {currentStep === 1 && (
        <>
          <h2 className="published-heading">
            Tell Us How Much You Can Earn From Your App?
          </h2>
          <p className="published-description">
            Can you provide a link to your application's listing on the relevant
            app store?
          </p>
          <input
            type="text"
            placeholder="Paste App Link"
            value={appLink}
            onChange={(e) => {
              setAppLink(e.target.value);
              if (appLinkError) {
                setAppLinkError(""); // Clear the appLinkError if it exists
              }
            }}
            // className={`app-link-input ${appLinkError ? "input-error" : ""}`}
          />

          {appLinkError && <p className="error-message">{appLinkError}</p>}
          <button onClick={handleNext} className="next-btn">
            {loading ? <div className="loader"></div> : "NEXT"}
          </button>
        </>
      )}
      {currentStep === 2 && (
        <>
          <h2 className="published-heading">My App is</h2>
          <label
            className={`radio-button-label ${
              AppPlateform === "ios" ? "selected" : ""
            }`}
          >
            <input
              type="radio"
              className="radio-button"
              value="ios"
              checked={AppPlateform === "ios"}
              onChange={handleChange}
            />
            iOS
          </label>

          <label
            className={`radio-button-label ${
              AppPlateform === "Android" ? "selected" : ""
            }`}
          >
            <input
              type="radio"
              className="radio-button"
              value="Android"
              checked={AppPlateform === "Android"}
              onChange={handleChange}
            />
            Android
          </label>
          <label
            className={`radio-button-label ${
              AppPlateform === "iOS & Android" ? "selected" : ""
            }`}
          >
            <input
              type="radio"
              className="radio-button"
              value="iOS & Android"
              checked={AppPlateform === "iOS & Android"}
              onChange={handleChange}
            />
            iOS & Android
          </label>
          {error && (
            <p className="error-message" style={{ textAlign: "left" }}>
              {error}
            </p>
          )}
          <button onClick={handleNext} className="next-btn">
            {loading ? <div className="loader"></div> : "NEXT"}
          </button>
        </>
      )}

      {currentStep === 3 && (
        <>
          <h2 className="published-heading">My App Type is</h2>
          <label
            className={`radio-button-label ${
              AppType === "Utility App" ? "selected" : ""
            }`}
          >
            <input
              type="radio"
              className="radio-button"
              value="Utility App"
              checked={AppType === "Mobile App"}
              onChange={handleChange3}
            />
            Utility App
          </label>

          <label
            className={`radio-button-label ${
              AppType === "Mobile Game" ? "selected" : ""
            }`}
          >
            <input
              type="radio"
              className="radio-button"
              value="Mobile Game"
              checked={AppType === "Mobile Game"}
              onChange={handleChange3}
            />
            Mobile Game
          </label>
          {error && (
            <p className="error-message" style={{ textAlign: "left" }}>
              {error}
            </p>
          )}
          <button onClick={handleNext} className="next-btn">
            {loading ? <div className="loader"></div> : "NEXT"}
          </button>
        </>
      )}

      {currentStep === 4 && (
        <>
          <h2 className="published-heading">My Traffic is</h2>

          <label
            className={`radio-button-label ${
              TrafficType === "Organic" ? "selected" : ""
            }`}
          >
            <input
              type="radio"
              className="radio-button"
              value="Organic"
              checked={TrafficType === "Organic"}
              onChange={handleChange1}
            />
            Organic
          </label>
          <label
            className={`radio-button-label ${
              TrafficType === "Paid" ? "selected" : ""
            }`}
          >
            <input
              type="radio"
              className="radio-button"
              value="Paid"
              checked={TrafficType === "Paid"}
              onChange={handleChange1}
            />
            Paid
          </label>
          <label
            className={`radio-button-label ${
              TrafficType === "Both" ? "selected" : ""
            }`}
          >
            <input
              type="radio"
              className="radio-button"
              value="Both"
              checked={TrafficType === "Both"}
              onChange={handleChange1}
            />
            Both
          </label>

          {error && <p className="error-message">{error}</p>}
          <button onClick={handleNext} className="next-btn">
            {loading ? <div className="loader"></div> : "NEXT"}
          </button>
        </>
      )}

      {currentStep === 5 && (
        <>
          <h2 className="published-heading">My App's Total Download Numbers</h2>
          <input
            type="number"
            placeholder="0"
            name="downloadednumbers"
            value={downloadednumbers}
            onChange={(e) => setDownloadednumbers(e.target.value)}
            // className={`app-link-input ${error ? 'input-error' : ''}`}
            className="number-input"
          />
          {error && <p className="error-message">{error}</p>}
          <button onClick={handleNext} className="next-btn">
            {loading ? <div className="loader"></div> : "NEXT"}
          </button>
        </>
      )}

      {currentStep === 6 && (
        <>
          <h2 className="published-heading">
            My App's Revenue For The Last 90 Days
          </h2>
          <input
            type="number"
            placeholder="Enter Revenue in USD$"
            name="revenue"
            value={revenue}
            onChange={(e) => {
              setRevenueError(""); // Clear revenue-specific error when user starts typing
              setRevenue(e.target.value);
            }}
            className="number-input"
          />
          {revenueError && <p className="error-message">{revenueError}</p>}

          <h2 className="published-heading">
            My App's Spending On The Campaign For The Last 90 Days
          </h2>
          <input
            type="number"
            placeholder="Enter Spending in USD$"
            name="Campaign"
            value={Campaign}
            onChange={(e) => {
              setCampaignError(""); // Clear campaign-specific error when user starts typing
              setCampaign(e.target.value);
            }}
            className="number-input"
          />
          {campaignError && <p className="error-message">{campaignError}</p>}

          <button onClick={handleNext} className="next-btn">
            {loading ? <div className="loader"></div> : "NEXT"}
          </button>
        </>
      )}

      {currentStep === 7 && (
        <>
          <h2 className="published-heading">Primary Source of Revenue</h2>

          <label
            className={`radio-button-label ${
              RevenueSource === "Advertising Revenue" ? "selected" : ""
            }`}
          >
            <input
              type="radio"
              className="radio-button"
              value="Advertising Revenue"
              checked={RevenueSource === "Advertising Revenue"}
              onChange={handleChange2}
            />
            Advertising Revenue
          </label>
          <label
            className={`radio-button-label ${
              RevenueSource === "In-app Purchase Revenue" ? "selected" : ""
            }`}
          >
            <input
              type="radio"
              className="radio-button"
              value="In-app Purchase Revenue"
              checked={RevenueSource === "In-app Purchase Revenue"}
              onChange={handleChange2}
            />
            In-app Purchase Revenue
          </label>
          <label
            className={`radio-button-label ${
              RevenueSource === "Transaction Revenue" ? "selected" : ""
            }`}
          >
            <input
              type="radio"
              className="radio-button"
              value="Transaction Revenue"
              checked={RevenueSource === "Transaction Revenue"}
              onChange={handleChange2}
            />
            Transaction Revenue
          </label>
          <label
            className={`radio-button-label ${
              RevenueSource === "other" ? "selected" : ""
            }`}
          >
            <input
              type="radio"
              className="radio-button"
              value="other"
              checked={RevenueSource === "other"}
              onChange={handleChange2}
            />
            Others
          </label>
          {error && <p className="error-message">{error}</p>}
          <button onClick={handleNext} className="next-btn">
            {loading ? <div className="loader"></div> : "NEXT"}
          </button>
        </>
      )}

      {currentStep === 8 && (
        <>
          <h2 className="published-heading">
            We've Calculated Your Valuation..
          </h2>
          <p className="published-description-step7">
            To access your valuation please fill in your contact details
          </p>
          <input
            type="text"
            placeholder="First Name"
            name="firstName"
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
              if (firstNameError) {
                setFirstNameError(""); // Clear the appLinkError if it exists
              }
            }}
            className="contact-input"
            required
          />
          {firstNameError && <p className="error-message">{firstNameError}</p>}
          <input
            type="text"
            placeholder="Surname"
            name="surname"
            value={surname}
            onChange={(e) => {
              setSurname(e.target.value);
              if (surnameError) {
                setSurnameError(""); // Clear the appLinkError if it exists
              }
            }}
            className="contact-input"
            required
          />
          {surnameError && <p className="error-message">{surnameError}</p>}
          <input
            type="email"
            placeholder="Email address"
            value={email}
            name="email"
            onChange={(e) => {
              setEmail(e.target.value);
              if (emailError) {
                setEmailError(""); // Clear the appLinkError if it exists
              }
            }}
            className="email-field"
            required
          />
          {emailError && <p className="error-message">{emailError}</p>}
          <input
            type="text"
            placeholder="LinkedIn Profile Link"
            name="linkedin"
            value={linkedin}
            onChange={(e) => {
              setLinkedin(e.target.value);
              if (linkedinError) {
                setLinkedinError(""); // Clear the appLinkError if it exists
              }
            }}
            className="contact-input"
            required
          />
          {linkedinError && <p className="error-message">{linkedinError}</p>}
          <button onClick={handleContactDetails} className="calc-btn">
            {loading ? <div className="loader"></div> : "CALCULATE NOW"}
          </button>
        </>
      )}

      {currentStep === 9 && (
        <>
          
          <div className="valuation-output">
          <div>
            Your app aproximated value
           </div>
            <div className="valuation-amount">${valuation}</div>
            <input
              type="range"
              min={valuation / 2}
              max={valuation * 2}
              value={valuation}
              onChange={handleSliderChange}
              className="valuation-slider"
            />
            <div className="slider-labels">
              <span style={{'textAlign' : 'left'}}>${valuation / 2}<br /><p style={{'fontWeight':'normal', 'fontSize':'10px', 'marginTop':'5%'}} 
              >Lower Qunatile</p></span>
              <span style={{'textAlign' : 'right'}}>${valuation * 2}<br /><p style={{'fontWeight':'normal', 'fontSize':'10px', 'marginTop':'5%'}} 
              >Upper Qunatile</p></span>
            </div>
            <div className="scraped-data-section">
              <div className="top-container">
             <div className="image-container">
              <img src={appLogo} alt="App Logo" className="app-logo" /></div>
             
             
             <div className="title-container">
              <p className="app-title" >{appTitle}</p>
              <p className="app-subtitle"> {appStoreName} </p></div></div>

              <div className="extra">

                <div className="extra-item">
                <strong style={{'color':'#2C6CD6 '}} > {appRatings}</strong> 
                <span>Rating</span></div>

                <div className="extra-item">
                <strong style={{'color':'#FF7A00 '}}>{appDownloads}</strong> 
                <span>Downloads</span> 
                 </div>

                 <div className="extra-item">
                <strong style={{'color':'#07C269  '}} > {appReviews} </strong>
                 <span >Reviews</span></div>
                  </div>
            </div>
            

              <p className="disclaimer-container">
              <strong>
                * Disclaimer:
              </strong>
              The valuation is an approximate assessment by Maida.Co and should
              not be interpreted as an official appraisal or financial guidance.
            </p>

            <label>
              <input
                type="checkbox"
                checked={isCheckboxChecked}
                onChange={(e) => setIsCheckboxChecked(e.target.checked)}
              />
              I agree to be contacted by Maida to discuss my valuation
            </label>
            <button
              onClick={handlePage}
              disabled={!isCheckboxChecked || loading}
              className="submit-button"
            >
              {loading ? <div className="loader"></div> : "Submit"}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default PublishedForm;
