// ValuationForm.jsx
import React, { useState } from 'react';
import './ValuationForm.css';
import ProgressBar from '../ProgressBar/ProgressBar'; // Adjust the import path as necessary
import UnPublishedForm from './UnPublishedForm/UnPublishedForm'; 
import PublishedForm from './PublishedForm/PublishedForm';
const ValuationForm = () => {
  const [appStatus, setAppStatus] = useState('');

  return (
    <>
    <div className="valuation-form">
      {appStatus === '' && (
        <>
          <h1 className="valuation-title">MAIDA.CO</h1>
          <button className="valuation-calculator-btn">Free App Valuation Calculator</button>
          <ProgressBar />
          <h2 className="valuation-heading">PROVIDE ADDITIONAL DETAILS ABOUT YOUR APP</h2>
          <p className="valuation-description">
            Is Your Application Currently Available on App Stores, or is it Still in the Development and Unpublished Phase?
          </p>
          <div className="button-group">
            <button onClick={() => setAppStatus('unpublished')} className="app-status-btn unpublished">
              UNPUBLISHED APP
            </button>
            <button onClick={() => setAppStatus('published')} className="app-status-btn published">
              PUBLISHED APP
            </button>
           
           
          </div>
          
          
        </>
      )}

      {appStatus === 'unpublished' && <UnPublishedForm />}
      {appStatus === 'published' && <PublishedForm />}
      {/* PublishedForm component would go here similarly if appStatus is 'published' */}
    </div>
    <div className='calculation'>
    <h2 className='calculation-heading'>Free App Valuation Calculator</h2>
          <p className='calculation-para'>Knowing how much your app is worth is important as 
          an app owner or publisher, and an app valuation calculator can determine your app’s value
           quickly and accurately. Whether  you decide to sell your app or not, it’s always a good idea
            to have it valued so you’re aware of its worth should the situation change.</p>
          <p className='calculation-para'>Even if selling your app isn't your immediate goal, being aware 
          of its market value is essential. This knowledge is particularly useful in attracting potential 
          investors who might be interested in further developing your app. </p>
          <p className='calculation-para'>While it may not be a part of your current development strategy, 
          understanding your app's value could become important in the future. A precise valuation is key when 
          you start conversations with prospective buyers or investors. They will be keen to understand the app's
           performance and its value proposition. </p>
          <p className='calculation-para'>So, what are the ways to determine your app's worth? Beyond using tools 
          like Maida's app worth calculator, there are various methods. The approach you select should align with 
          your app's scale, framework, and duration in the market.</p>
          <h3 className='calculation-h3'>How to calculate the value of your app</h3>
          <p className='calculation-para'>If your
          <strong> end users are paying customers</strong> , you can use the formula below 
          to calculate your app’s value. This formula will calculate the
          <strong> total value of your existing user base.</strong> </p>
          <p className='calculation-para'><strong>Value = Number of Users x Net Profit Per User
           (User Lifetime Value - Customer Acquisition Cost)</strong> </p>
          <h4 className='calculation-h4'>What is User Lifetime Value (LTV)? </h4>
          <p className='calculation-para'>The lifetime value (LTV) of a user is the average amount of money a user will
           spend over time using your app’s products and services. </p>
          <h4 className='calculation-h4'>What is Customer Acquisition Cost (CAC)? </h4>
          <p className='calculation-para'>Customer Acquisition Cost (CAC) is the average cost of acquiring a new user 
          through your marketing or sales efforts.</p>
          <p className='calculation-para'>By subtracting the cost of acquiring a new user from the average lifetime value,
           you can calculate your net profit generated per user. Multiply this figure (net profit per user) by the total number
            of users your app has. The figure you get will give you an idea of the total value of your current user base.  </p>
          <p className='calculation-para'>Another way to calculate your app’s value is to <strong> multiply its average monthly revenue 
          by a specific number of months. </strong> This method will calculate your app’s value <strong> based on the monthly revenue it generates
           over time.</strong>  </p>
           <p className='calculation-para'>For example, if your app’s monthly revenue is approximately $600 and has been on the 
           App Store for less than 6 months, you may want to sell it for the price of its value at 6 months.  </p>
           <p className='calculation-para'><strong>Value = $600 x 6 which equals $3600</strong> </p>
           <p className='calculation-para'>If your app has been on the App Store for longer than 6 months, then you might want 
           to value its worth at a year and sell it at this cost. </p>
           <p className='calculation-para'><strong>Value = $600 x 12 which equals $7200 </strong> </p>
                  
          </div>
    </>);
};

export default ValuationForm;
